export const Netflix = {
    name: 'Netflix-Clone',
    time:'Sep 2022',
    desc: 'Developed a Netflix clone app as a side project, showcasing skills in UI/UX design, authentication, content delivery, and personalized recommendations. Enhanced streaming experience with offline viewing and adaptive bitrate.',
    tech: ['React','HTML','Axios','CSS'],
    url: 'https://github.com/sritharni/NETFLIX_CLONE'
}
export const GithubFinder = {
    name: 'Github Finder',
    time: 'Oct 2021',
    desc: 'Created GitHub Finder, a side project demonstrating proficiency in web development. Implemented user search, repository details, and user-friendly interface using HTML, CSS, and JavaScript. Enhanced skills in front-end development.',
    tech: ['React','HTML','Axios','CSS'],
    url:'https://github.com/sritharni/github-finder'
}
export const BugHound = {
    name: 'Bug Hound',
    time: 'Apr 2023',
    desc: 'Developed a Bug Reporting Website as a side project, streamlining issue tracking. Utilized HTML, CSS, and JavaScript for user-friendly interface. Strengthened skills in web development and issue resolution processes.',
    tech: ['React','HTML','Axios','CSS'],
    url:'https://github.com/sritharni/BugHound'
}
export const Tata_Elxsi = {
    name: 'Tata Elxsi',
    time: 'Jul 2020 - Jul 2022',
    desc: 'As a key member of Tata Elxsi’s white-labelled OTT product line, worked towards the development of a web application’s front end from the ground up. Developed ergonomic login module and other forms. Coded responsive web pages that adapt to different screen sizes and devices.',
    tech: ['React','HTML','Axios','CSS', 'Redux'],
    // url:'https://github.com/sritharni/BugHound'
}
export const Cognitive_mobiles = {
    name: 'Cognitive Mobiles',
    time: 'Apr 2020 - Jun 2020',
    desc: 'Contributed on a video conferencing application by integrating JITSI functionality as a plug-in, tailoring it to meet our client’s specific needs which led to 15% increase in user adoption.Collaborated with Android team and utilized React Native to enhance the android application, resulting in a remarkable 32% improvement',
    tech: ['React','HTML','Axios','CSS', 'Redux'],
    // url:'https://github.com/sritharni/BugHound'
}
export const ASI_CSULB = {
    name: 'Associated students Inc, CSULB',
    time: 'Oct 2022 - May 2024',
    desc: 'Developed ’22WestMedia’, a web app using React JS and Redux unifying CSULB media platforms.Enhanced user engagement by 30% and reduced access time by 20%',
    tech: ['React','HTML','Axios','CSS', 'Redux'],
    // url:'https://github.com/sritharni/BugHound'
}


