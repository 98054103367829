import styles from './App.module.css';
import React from 'react';
import Button from './Elements/Button';
import Container from './Elements/Container';
import {Netflix,GithubFinder,BugHound, Tata_Elxsi,Cognitive_mobiles, ASI_CSULB} from './Elements/projectDesc';
import {Git, HTML, CSS, Javascript, Postman, ReactJS, redux, restfulApi, Bootstrap, Jquery} from './Components/Skills';
import About from './Components/About';

document.body.style = "background: #282c34; font-family: 'Lato', sans-serif";
// document.title = 'Sritharni Portfolio';

function App() {
  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        {/* <div className= {styles.semiCircle}></div> */}
        <img className={styles.photo} src='/Images/tharni.jpeg' alt="Profile_picture"></img>
        <h1 style={styles.headerText}>Hi,</h1> <h3>Welcome to my portfolio, I'm Sritharni Chellan Natarajan, I've a preferred name "Tharni"</h3> 
        <div className={styles.buttons}>
        <Button className = {styles.button} content='#about' name = "About"/>
        <Button className = {styles.button} content='#projects' name = "Projects"/>
        <Button className = {styles.button} content='#skills' name = "Skills"/>
        <Button className = {styles.button} content='#workExperience' name = "Work Experience"/>
        <Button className = {styles.button} content='https://drive.google.com/file/d/153sbY2jAXttD3YX8xRux3OhEz6R2eTfP/view'target= "_blank" name = "Resume"/>
        <Button className = {styles.button} content='https://www.linkedin.com/in/sritharninatarajan/' target= "_blank" name = "Linkedin"/>
        <Button className = {styles.button} content='https://github.com/sritharni' target= "_blank" name = "Github"/>
        <Button className = {styles.button} content='https://drive.google.com/file/d/1lkKThwGWpLiitS1BrSKYSOSG8K4TNZEm/view' target= "_blank" name = "Transcript"/>



        </div>
      </header>
      <div id='about' className={styles.AppHeader}>
        {/* ABOUT */}
        <About/>
      </div>
     
      <div id='projects' className={styles.AppHeader}>
        {/* PROJECTS */}
        <h1 style={{color:'#786fa6'}}> PROJECTS</h1>
        <div className={styles.container}>
          <Container projectData={Netflix} isGitIconRequired/>
          <Container projectData={GithubFinder} isGitIconRequired/>
          <Container projectData={BugHound} isGitIconRequired/>
        </div>

      </div>
      <div id='skills' className={styles.AppHeader}>
        {/* SKILLS */}
        <h1 style={{color:'#bae1ff'}}> TECHNICAL SKILLS</h1>
        <div className={styles.skillscontainer}>
            <div>
              <div><img className={styles.skillsLogo} src={Javascript.img} alt="JS logo"/></div>
              <div><img className={styles.skillsLogo} src={HTML.img} alt="HTML logo"/></div>
              <div><img className={styles.skillsLogo} src={CSS.img} alt="CSS logo"/></div>
            </div>

            <div>
              <div><img className={styles.skillsLogo} src={ReactJS.img} alt="React logo"/></div>
              <div><img className={styles.skillsLogo} src={redux.img} alt="Redux logo"/></div>
              <div><img className={styles.skillsLogo} src={restfulApi.img} alt="Restful API logo"/></div>


              {/* <div className={styles.myskills}><img src={node.img}/></div> */}

            </div>

            <div>
              <div><img className={styles.skillsLogo} src={Postman.img} alt="Postman logo"/></div>
              <div><img className={styles.skillsLogo} src={Git.img} alt="Git logo"/></div>
              <div><img className={styles.skillsLogo} src={Bootstrap.img} alt="Bootstrap logo"/></div>

            </div>

            <div>
              <div><img className={styles.skillsLogo} src={Jquery.img} alt="Jquery logo"/></div>
              
            </div>
        </div>

      </div>
      <div id='workExperience' className={styles.AppHeader}>
        {/* WORK EXPERIENCE */}
        <h1 style={{color:'#ffb3ba'}}> WORK EXPERIENCE</h1>
        <div className={styles.workExp}>
        <Container projectData={Tata_Elxsi}/>
        <Container projectData={Cognitive_mobiles}/>
        <Container projectData={ASI_CSULB}/>


        </div>
      </div>
    </div>
  );
}

export default App;
