import React from 'react'
import styles from '../App.module.css';


export default function About() {
  return (
        <div className={styles.abt}>
          <h1> ABOUT</h1>
          <p>I'm a professional who has an unquenchable thirst for knowledge. I'm a proud front end developer, it allows me to be creative. As I enjoy developing client side, I decided to make this as my hobby. I work on my personal projects when I'm free. Presently I'm pursuing a master's program from CSULB, California, to upskill. Besides this, I'm an avid reader and I dance to sweat my stress.
              please feel free to contact me incase of any queries.</p>
        </div>
  )
}
