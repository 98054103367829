import React from 'react'
import styles from '../App.module.css';

export default function Button({name,content,target}) {
  return (
    <div>
        <a className={styles.navButtonsLink} href={content} target={target}>
          <button className={styles.button}>
            {name}
          </button>
        </a>
    </div>
  )
}
