import React from 'react'
import styles from '../App.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function Container({projectData, isGitIconRequired}) {
  return (
    <div>
        <div className={styles.projectContainer}>{projectData.name}
            
              {isGitIconRequired? 
                <a target='_blank' rel="noreferrer" href={projectData.url}><span className={styles.icon}><FontAwesomeIcon icon={faGithub} /></span></a>
              :null
              }
            
            <p className={styles.timeline}>{projectData.time}</p>
            <p className={styles.projectDesc}>{projectData.desc}</p>
            <div>{projectData.tech.map((item, i) => <span key={i} className={styles.skills}>{item}</span>)}</div>
          </div>
    </div>
  )
}
