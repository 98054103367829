export const ReactJS ={
    skillName : 'React JS',
    icon : 'faReact',
    img : '/Images/react.png'
}
export const redux ={
    skillName : 'Redux',
    img : '/Images/redux.png'

}
export const restfulApi ={
    skillName : 'RESTful API',
    img : '/Images/restful_api.png'
}
export const node ={
    skillName : 'Node JS',
    img : '/Images/node.png'
}
export const Javascript ={
    skillName : 'Javascript',
    icon : 'faJs',
    img : '/Images/js.png'

}
export const Git ={
    skillName : 'Git',
    icon : 'faGit',
    img : '/Images/git.png'

}
export const Postman ={
    skillName : 'Postman',
    img : '/Images/postman.png'
}
export const HTML ={
    skillName : 'HTML',
    img : '/Images/html.png'
}

export const CSS ={
    skillName : 'CSS',
    img : '/Images/css.png'
}

export const Bootstrap ={
    skillName : 'Bootstrap',
    img : '/Images/bootstrap.png'
}

export const Jquery ={
    skillName : 'Jquery',
    img : '/Images/jquery.png'
}

